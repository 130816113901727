:root{
    --box_border : #2196F3;
    --green : #033E30;
  --button_border : #E5E5E5;
  --white : #fff;
  --black : #000;
  --sky : #00ABD2;
  --sky_secondary : #EEFCFF;
  --brown : #AE4F00;
  --brown_secondary : #FEECDE;
  --cal_green : #21AB70;
  --cal_greenSecondary : #EDFDF6;
  --newSky : #4193FF;
  --newSky_secondary : #EDF5FF;
  --violet : #560074;
  --violet_secondary :  #f4d4ff;
  --extra_date : #F7F7F7;
  --notify_box : #E7E7E7;
  --grayer : #7D7D7D;
  --lite_lime : #F8FFFD;
  --taber_border : #CBCBCB;
  --superAdmin_black : #161C2D;
  --weight_four: 400;
  --weight_five: 500;
  --weight_six: 600;
  --weight_seven: 700;
  --weight_eight: 800;
  --weight_nine: 900;
  --small_font: 8px;
  --lower_fontsize: 10px;
  --font_eleven : 11px;
  --font_twelve: 12px;
  --font_thirteen: 13px;
  --font_fifteen: 15px;
  --font_sixteen: 16px;
  --font_eighteen: 18px;
  --font_twenty: 20px;
  --font_twentyfive: 25px;
  --higher_fontsize: 30px;
  --font_thirtyfive: 35px;
  --font_fourty: 40px;
  --font_fourtyfive: 45px;
  --font_fifty: 50px;
}
/* common css */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--green); 
    border-radius: 10px;
  }
  
/* end of common css */

/* dashboard css */
.dash__singleBox{
    border: 1px solid var(--box_border);
    min-height: 130px;
    height: 100%;
}
.dash__singleBoxLogos{
    height: 30px;
    width: 30px;
}
.dash__singleBoxLabel{
    color: var(--black);
    font-size: var(--font_twelve);
    font-weight: var(--weight_five);
}
.dash__singleBoxWhiteLabel{
    color: var(--white);
    font-size: var(--font_twelve);
    font-weight: var(--weight_five);
}
.dash__singleBoxValue{
    color: var(--black);
    font-size: var(--font_eighteen);
    font-weight: var(--weight_five);
}
.dash__singleBoxGraph{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
.dash__lottieHolder{
    height: 80px;
    width: 80px;
}
.dash__lottieBox{
   background-color: var(--green);
   min-height: 130px;
   border: 1px solid var(--green);
   height: 100%;
}
/* end of dashboard css */

/* calendar css */
.cal__holderTab{
    height: 5px;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .cal__skyHolder{
    background-color: var(--sky);
  }
  .cal__brownHolder{
    background-color: var(--brown);
  }
  .cal__newSkyHolder{
    background-color: var(--newSky);
  }
  .cal__violetHolder{
    background-color: var(--violet);
  }

  .calendar__holder .rbc-event,.calendar__holder .cal__eventHolder{
    padding: 0px;
    background-color: transparent;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .calendar__holder .cal__skyEventHolder{
    background-color: var(--sky_secondary);
  }
  .calendar__holder .cal__brownEventHolder{
    background-color: var(--brown_secondary);
  }
  .calendar__holder .cal__newSkyEventHolder{
    background-color: var(--newSky_secondary);
  }
  .calendar__holder .cal__violeEventHolder{
    background-color: var(--violet_secondary);
  }
  .cal__rightBadge{
    height: 25px;
    width: 25px;
    padding: 3px 5px;
  }
  .cal__skyBadge{
    background-color: var(--sky);
  }
  .cal__brownBadge{
    background-color: var(--brown);
  }
  .cal__newSkyBadge{
    background-color: var(--newSky);
  }
  .cal__violetBadge{
    background-color: var(--violet);
  }
  .cal__rightBadge p{
    color: var(--white);
    font-size: var(--font_thirteen);
  }
  .cal__eventTitle{
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
  }
  .cal__eventTime{
    font-size: var(--font_twelve);
    font-weight: var(--weight_four);
  }
  .cal__skyEventTime{
    color: var(--sky);
  }
  .cal__brownEventTime{
    color: var(--brown);
  }
  .cal__newSkyEventTime{
    color: var(--newSky);
  }
  .cal__violetEventTime{
    color: var(--violet);
  }
  .rbc-toolbar button,.rbc-toolbar button:active,.rbc-toolbar button:focus,.rbc-toolbar button:focus-visible,.rbc-toolbar button:hover{
      border: 1px solid var(--button_border) !important;
      margin-right: 10px !important;
      border-radius: 5px  !important;
      padding: 10px 15px !important;
      font-size: var(--font_thirteen) !important;
      font-weight: var(--weight_five) !important;
  }
  .rbc-toolbar button.rbc-active{
    background-color: var(--green) !important;
    color: var(--white);
  }
  .rbc-toolbar button:active,.rbc-toolbar button:focus,.rbc-toolbar button:focus-visible{
    color: var(--white) !important;
  }
  .rbc-date-cell button{
    font-size: var(--font_twelve) !important;
  }
  .calendar__holder .rbc-event.rbc-selected,.calendar__holder .rbc-day-slot .rbc-selected.rbc-background-event{
    background-color: transparent;
  }
  .calendar__holder .rbc-header{
    padding: 10px !important;
  }
  .calendar__holder .rbc-header span{
    font-weight: var(--weight_four) !important;
    font-size: var(--font_fifteen) !important;
    padding: 5px !important;
  }
  .calendar__holder .rbc-day-slot .rbc-event,.calendar__holder .rbc-day-slot .rbc-background-event{
    border: none !important;
  }
  .calendar__holder.rbc-event{
    background-color: transparent;
  }
  .calendar__holder .rbc-off-range-bg{
  background: var(--extra_date) !important;
  }
  .calendar__holder .rbc-month-view{
    border-radius: 10px !important;
  }
.rbc-addons-dnd.rbc-calendar{
    min-width: 900px;
}
.calendar__scoller{
    overflow-x: auto;
}
/* end of calendar css */

/* notificatin css */
.notify__holder{
    border: 1px solid var(--extra_date);
    background-color: var(--notify_box);
    max-height: 600px;
    overflow-y: auto;
}
.single__notify{
    border: 1px solid var(--extra_date);
}
.notify__title{
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.notify__img{
    height: 40px;
    width: 40px;
}
.notify__time{
    color: var(--black);
    font-size: var(--lower_fontsize);
    font-weight: var(--weight_five);
}
/* end of notification css */
/* super admin css */
.providerCard_caretype{
  color: var(--grayer);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.superAdmin__title{
  color: var(--black);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.superAdmin__shadowBox{
  box-shadow: 0px 0px 4px 0px lightgray;
}
.superAdmin__limeBox{
  background-color: var(--lite_lime);
  box-shadow: 2px 2px 4px 0px lightgray;
}
.superAdmin__greenBtn{
  border: 1px solid var(--green);
  outline: none;
  background-color: var(--green);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  padding: 8px 12px;
}
.superAdmin__greenBtn svg{
  height: 15px;
  width: 15px;
}
.superAdmin__greenBtn:hover{
  background-color: transparent;
  color: var(--green);
}
.superAdmin__providerDtlsHolder{
  height: 90px;
}
.superAdmin__providerDtls{
  width: fit-content;
  top: -50px;
}
.superAdmin__providerImg{
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
}
.superAdmin__providerName{
  color: var(--black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.superAdmin__taberTable{
  border: 1px solid var(--taber_border);
}
.superAdmin__taberGrnBtn{
  border: none;
  outline: none;                              
  color: var(--superAdmin_black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-color: transparent;
}
.superAdmin__taberGrnBtn.active{
  background-color: var(--green);
  color: var(--white);
}
/* end of super admin css */

/* media query */
@media (width < 768px){
    .rbc-toolbar{
        flex-direction: row !important;
    }
}
/* end of media query */

