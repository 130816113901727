/* start of common css */

.mt_2rem {
  margin-top: 2rem;
}
.pt_2rem {
  padding-top: 2rem;
}

.pm_button_one {
  background: var(--darkGreenClr);
  border: 1px solid var(--darkGreenClr);
  color: var(--whiteClr);
  font-weight: 550;
  font-size: 13px;
  padding: 5px 25px;
  border-radius: 3px;
  transition: 0.3s;
}
.pm_button_one:hover {
  background: transparent;
  border: 1px solid var(--darkGreenClr);
  color: var(--darkGreenClr);
}
.pm_button_two {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  color: var(--grayClr);
  font-weight: 400;
  font-size: 13px;
  padding: 5px 25px;
  border-radius: 3px;
  transition: 0.3s;
}
.pm_button_two:hover {
  background: var(--grayClr);
  border: 1px solid var(--grayClr);
  color: var(--whiteClr);
}
.border_less_button {
  border: none;
  outline: none;
  background: transparent;
  color: var(--darkGreenClr);
  font-size: 15px;
}
.choose_file_button {
  background: var(--green1);
  color: var(--grayClr);
  border: none;
  outline: none;
  width: 120px;
  height: 100%;
  border: 1px solid var(--lightGrayClr);
  border-radius: 5px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
}
.gren_btn {
  background: var(--darkGreenClr);
  color: var(--whiteClr);
  border: none;
  outline: none;
  padding: 5px 10px;
  border: 1px solid var(--darkGreenClr);
  border-radius: 3px;
  font-size: 13px;
  top: 0;
  right: 0;
}
.pm_button_three {
  background: var(--darkGreenClr);
  border: 1px solid var(--darkGreenClr);
  color: var(--whiteClr);
  font-weight: 500;
  font-size: 16px;
  padding: 8px 25px;
  border-radius: 3px;
  transition: 0.3s;
}
.pm_red_btn {
  background: var(--redClr3);
  border: 1px solid var(--redClr2);
  color: var(--redClr2);
  font-size: 14px;
  font-weight: 450;
  padding: 5px 19px;
  border-radius: 5px;
  transition: 0.3s;
}
.pm_orange_btn {
  background: var(--orangeClr2);
  border: 1px solid var(--orangeClr);
  color: var(--orangeClr);
  font-size: 14px;
  font-weight: 450;
  padding: 5px 15px;
  border-radius: 5px;
  transition: 0.3s;
}
.pm_button_three:hover {
  transform: scale(99%);
}
.pm_red_btn:hover,
.pm_orange_btn:hover,
.pm_green_btn:hover {
  transform: translateY(-5px);
}
.pm_green_btn {
  background: var(--greenClr5);
  border: 1px solid var(--greenClr5);
  color: var(--whiteClr);
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
}

.line_button {
  color: var(--darkGreenClr);
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
}
.fw_450 {
  font-weight: 450;
}
.fw_550 {
  font-weight: 550;
}
.text_black_sm {
  color: var(--grayClr);
  font-size: 14px;
}
.text_black_md {
  color: var(--grayClr);
  font-size: 14px;
  font-weight: 550;
}
.text_black_lg {
  color: var(--darkGrayclr);
  font-weight: 700;
  font-size: 15px;
}
.text_green_lg {
  color: var(--darkGreenClr);
  font-weight: 700;
  font-size: 15px;
}
.text_green_md {
  font-size: 12px;
  color: var(--darkGreenClr);
  font-weight: 700;
  font-size: 15px;
}
.gray_text_sm {
  font-size: 14px;
  color: var(--lightGrayClr2);
}
.text_black_xs {
  color: var(--darkGrayclr);
  font-size: 13px;
}
.text_black_xs_bold {
  font-weight: 500;
  color: var(--darkGrayclr);
  font-size: 14px;
}
.text_black_xxs {
  color: var(--darkGrayclr);
  font-size: 12px;
}
.text_white_xs {
  font-size: 9px;
  color: var(--whiteClr);
}
.text_gray_lg {
  color: var(--grayClr);
  font-weight: 600;
  /* font-size: 14px; */
}
.text_dark_md {
  color: var(--blackClr5);
  font-weight: 450;
}
.text_gray_sm {
  font-size: 13px;
  color: var(--lightGrayClr14);
}

.text_red_bg {
  background: var(--redClr3);
  padding: 8px 15px;
  color: var(--redClr2);
  font-size: 13px;
  width: fit-content;
  text-transform: capitalize;
  font-weight: 500;
}
.img_container_fit {
  min-width: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}
.auth_error_text {
  color: var(--redClr6);
  font-size: 14px;
}
.table_green_text {
  color: var(--greenClr6) !important;
}
.table_red_text {
  color: var(--redClr7) !important;
}

/* end of common css */

.primary_card {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  padding: 10px;
  border-radius: 5px;
}

.requried_icon {
  color: var(--redClr);
}
.prm_textarea {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  padding: 8px;
  font-size: 14px;
  color: var(--grayClr);
  min-height: 110px;
  max-width: 95%;
  border-radius: 5px;
}
.prm_textarea:focus-visible {
  outline: none;
}
.prm_textarea_md {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  padding: 8px;
  font-size: 14px;
  color: var(--grayClr);
  min-height: 250px;
  max-width: 95%;
  border-radius: 5px;
}
.prm_textarea_md:focus-visible {
  outline: none;
}
.th_json {
  width: 100px;
}

/* start of modal css -1 */

.th_custom_modal {
  font-family: var(--poppins_font);
}
.th_custom_modal .modal-header {
  border: none;
  padding: 0px;
}
.th_custom_modal .modal-body {
  padding: 20px;
}
.th_custom_modal .circle_cls_button {
  right: -14px;
  top: -14px;
  background: var(--darkGreenClr);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  z-index: 2;
}

.th_custom_modal .circle_cls_button svg {
  color: var(--whiteClr);
}
/* end of modal css -1 */

/* start of modal css -2 */

.custom_modal_border {
  font-family: var(--poppins_font);
}
.custom_modal_border .modal-header {
  border: none;
  padding: 0px;
}
.custom_modal_border .modal-body {
  padding: 15px;
  padding-top: 35px;
}

.modal_border {
  border: 1px solid var(--lightGrayClr);
  border-radius: 5px;
  padding: 18px;
}
.custom_modal_border .circle_cls_button {
  right: -14px;
  top: -14px;
  background: var(--darkGreenClr);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  z-index: 2;
}

.custom_modal_border .circle_cls_button svg {
  color: var(--whiteClr);
}
.mdl_logo {
  width: 130px;
}
.mdl_body_title {
  border-bottom: 1px solid var(--lightGrayClr);
}
.mdl_body_content {
  border-bottom: 1px solid var(--lightGrayClr);
}
.text_wt_border {
  border: 1px solid var(--lightGrayClr);
  padding: 10px;
}
.sign_img {
  width: 150px;
  max-width: 150px;
}

/* end of modal css -2 */

/* start of custom table bootstrap */

.custom_bs_table .table thead tr th {
  background: var(--lightGrayClr3);
  color: var(--lightGrayClr4);
  font-size: 15px;
  font-weight: 400;
  min-width: 170px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}
.custom_bs_table .table tbody tr td {
  text-align: center;
  color: var(--darkGrayClr2);
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
  border-bottom: none;
}
.custom_bs_table .table thead tr th:first-child {
  min-width: 100px;
}
.custom_bs_table .table {
  /* border-collapse: unset !important; */
}
.custom_bs_table .table thead tr {
  background: red !important;
}
.custom_bs_table .table tbody {
  border: 1px solid var(--lightGrayClr5);
}

/* end of custom table bootstrap */

.custom_label {
  color: var(--grayClr);
  font-size: 14px;
  padding-left: 0px;
}
.custom_input {
  border: 1px solid var(--lightGrayClr);
  background: var(--lightGrayClr3);
  height: 42px;
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
  color: var(--grayClr);
  padding-left: 15px;
  padding-right: 15px;
}
.choose_custom_input {
  border: 1px solid var(--lightGrayClr);
  background: var(--lightGrayClr3);
  height: 42px;
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
  color: var(--grayClr);
  padding-left: 15px;
  /* padding-right: 15px; */
}
.choose_file {
  height: 100%;
  width: calc(100% - 120px);
}
.custom_input::placeholder {
  color: var(--grayClr);
}
.custom_input:focus-visible {
  outline: none;
}
.custom_textarea {
  border: 1px solid var(--lightGrayClr);
  background: var(--lightGrayClr3);
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
  color: var(--grayClr);
  padding: 15px;
  min-height: 100px;
}
.custom_textarea::placeholder {
  color: var(--grayClr);
}
.custom_textarea:focus-visible {
  outline: none;
}
.choosen_file_name {
  width: 100%;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.upload_image_wraper {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  min-height: 100px;
  height: 100px;
  max-height: 100px;
  border: 4px solid var(--lightGrayClr6);
  border-radius: 50%;
  background: var(--lightGrayClr7);
}
.upload_image_wraper img {
  object-fit: cover;
}

/* start of accordion */
.custom_accor .accordion-button:focus {
  box-shadow: none;
}
.custom_accor .accordion-button:not(.collapsed) {
  background-color: var(--lightGrayClr3);
  box-shadow: none;
  border-radius: 10px !important;
  border: 1px solid var(--lightGrayClr6) !important;
  color: var(--blackClr);
  font-weight: 550;
}
.custom_accor .accordion-button {
  background-color: var(--lightGrayClr3);
  box-shadow: none;
  border-radius: 10px !important;
  border: 1px solid var(--lightGrayClr6) !important;
  color: var(--blackClr);
  font-weight: 550;
  font-size: 12px;
}
.custom_accor .accordion-header {
}
.custom_accor .accordion-item {
  margin-bottom: 1rem;
  border: none;
}
.custom_accor .accordion-button:not(.collapsed)::after {
}
.custom_accor .accordion-body {
  font-size: 13px;
}
.custom_accor .accordion-body.doctor_education_body {
  border: 1px solid var(--lightGrayClr6);
  margin-top: 1rem;
  border-radius: 10px;
}

/* end of accordion */

/* start of custom checkbox */

.custom_chckbox .form-check-input[type="checkbox"] {
  margin-top: 0px !important;
}
.custom_chckbox .form-check-label {
  color: var(--darkGreenClr);
  font-size: 14px;
  font-weight: 500;
}

/* end of custom checkbox */

/* start of custom radio */

.custom_radio_button .container input:checked ~ .checkmark {
  background-color: var(--darkGreenClr);
  border: none;
}
.custom_radio_button .container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom_radio_button .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom_radio_button .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid var(--blackClr);
}

/* On mouse-over, add a grey background color */
.custom_radio_button .container:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom_radio_button .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom_radio_button .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom_radio_button .container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

/* end of custom radio */

/* start of custom toggle */

.custom_toggle .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;
}

.custom_toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_toggle .slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.custom_toggle input:checked + .slider {
  background-color: var(--darkGreenClr);
}

.custom_toggle input:focus + .slider {
  box-shadow: 0 0 1px var(--darkGreenClr);
}

.custom_toggle input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* end of custom toggle */

/* start of react select */

.custom_react_select__control {
  background: transparent !important;
  border: 1px solid var(--lightGrayClr) !important;
  min-height: 50px !important;
  padding-top: 0 !important;
  border-radius: 25px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.custom_react_select__control--is-focused,
.custom_react_select__control--menu-is-open {
  box-shadow: none !important;
}
.custom_react_select__indicator-separator {
  display: none !important;
}
.custom_react_select__placeholder,
.custom_react_select__single-value {
  color: var(--grayClr) !important;
  font-size: 13px !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}
.custom_react_select__indicator {
  padding: 0px !important;
  padding-left: 3px !important;
}
.custom_react_select__indicator svg {
  width: 16px;
  fill: var(--grayClr);
}
.custom_react_select__value-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom_react_select__menu {
  /* background-color: var(----lightGrayClr3) !important; */
  border: 1px solid var(--lightGrayClr) !important;
  box-shadow: none !important;
  border-radius: 25px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.custom_react_select__option {
  padding-top: 8px !important ;
  font-size: 12px !important;
  cursor: pointer !important;
}
.custom_react_select__option--is-focused,
.custom_react_select__option--is-selected {
  background: transparent !important;
  color: var(--darkGreenClr) !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}
.custom_react_select__menu-list {
  padding: 0px !important;
}

/* end of react select */

.doctor_custom_input {
  background: transparent !important;
  border: 1px solid var(--lightGrayClr) !important;
  min-height: 50px !important;
  border-radius: 25px !important;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--grayClr);
  font-size: 13px;
}
.doctor_custom_input::placeholder {
  color: var(--grayClr);
  font-size: 13px;
}
.doctor_custom_input:focus-visible {
  outline: none;
}
.link_span {
  color: var(--darkGreenClr);
  text-decoration: underline;
}
.uplaod_container {
  min-height: 200px;
  border: 1px solid var(--lightGrayClr6);
  border-radius: 15px;
  background: var(--lightGrayClr3);
}
.upload_prescription {
  border: 1px solid var(--lightGrayClr6);
  border-radius: 15px;
  background: var(--lightGrayClr3);
  padding: 15px 25px;
}
/* media */

@media (min-width: 576px) {
  .th_custom_modal .modal-body {
    padding: 30px;
  }
  .primary_card {
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .prm_textarea,
  .prm_textarea_md {
    max-width: 90%;
  }
  .text_black_lg {
    font-size: 17px;
  }
  .text_green_lg {
    font-size: 17px;
  }
  .text_green_md {
    font-size: 14px;
  }
  .text_black_md {
    font-size: 16px;
  }
  .custom_accor .accordion-button {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .ps_xl_2rem {
    padding-left: 2rem !important;
  }
  .pe_xl_2rem {
    padding-right: 2rem !important;
  }
}

@media (max-width: 1199px) {
  .custom_container_padding {
    padding-left: 17px;
    padding-right: 17px;
  }
}

/* start of  admin panel*/

.admin_creds {
  height: 100vh;
  min-height: 100vh;
  background: var(--greenClr3);
  font-family: var(--poppins_font);
}

.auth_cards {
  padding: 30px;
  border: 1px solid var(--darkGreenClr);
  border-radius: 10px;
  /* box-shadow: 0px 0px 0px gray; */
  color: var(--darkGrayClr4);
}
.auth_label {
  color: var(--darkGrayClr5);
  font-size: 15px;
}
.auth_input {
  background: var(--green1);
  border: none;
  outline: none;
  height: 40px;
  font-size: 14px;
  color: var(--darkGrayClr5);
  border-radius: 5px;
  border: 1px solid var(--greenClr4);
  opacity: 1;
}
.auth_input::placeholder {
  color: var(--darkGrayClr5);
  opacity: 0.6;
}
.auth_pass_wrap {
  background: var(--green1);
  border: none;
  outline: none;
  height: 40px;
  font-size: 14px;
  color: var(--darkGrayClr5);
  border-radius: 5px;
  border: 1px solid var(--greenClr4);
}
.auth_pass_wrap input {
  background: transparent;
  border: none;
  outline: none;
  color: var(--darkGrayClr5);
  font-size: 14px;
  width: 100%;
  opacity: 1;
}
.auth_pass_wrap input::placeholder {
  color: var(--darkGrayClr5);
  font-size: 14px;
  opacity: 0.6;
}
.auth_pass_icons {
  border: none;
  outline: none;
  background: transparent;
  height: 100%;
}
.auth_pass_icons svg {
  fill: var(--darkGrayClr5);
}
.prov_app_card {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr15);
  border-radius: 8px;
  padding: 8px;
  max-width: 288px;
  box-shadow: 0px 0px 4px var(--lightGrayClr15);
}
.prov_img_wrap {
  min-width: 270px;
  width: 270px;
  max-width: 270px;
  min-height: 245px;
  height: 245px;
  max-height: 245px;
}

.prov_img_wrap img {
  object-fit: cover;
  border-radius: 8px;
}
.prov_btn_wrap {
  max-width: 90%;
  margin: 0 auto;
}
.table_img_wrap {
  min-width: 30px;
  width: 30px;
  max-width: 30px;
  min-height: 30px;
  height: 30px;
  max-height: 30px;
  border-radius: 50%;
}
.table_img_wrap img {
  object-fit: cover;
  border-radius: 50%;
}
.supporttable .table thead th {
  min-width: 180px;
}
/* media */

@media (min-width: 992px) {
  .text_gray_lg {
    /* font-size: 18px; */
  }
}
@media (min-width: 1400px) {
}

@media (min-width: 1500px) {
  .col-xxxl-1 {
    flex: 0 0 8.333333% !important;
    max-width: 8.333333% !important;
  }

  .col-xxxl-2 {
    flex: 0 0 16.666667% !important;
    max-width: 16.666667% !important;
  }
  .col-xxxl-2_5 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }

  .col-xxxl-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-xxxl-4 {
    flex: 0 0 33.333333% !important;
    max-width: 33.333333% !important;
  }

  .col-xxxl-5 {
    flex: 0 0 41.666667% !important;
    max-width: 41.666667% !important;
  }

  .col-xxxl-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .col-xxxl-7 {
    flex: 0 0 58.333333% !important;
    max-width: 58.333333% !important;
  }

  .col-xxxl-8 {
    flex: 0 0 66.666667% !important;
    max-width: 66.666667% !important;
  }

  .col-xxxl-9 {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .col-xxxl-10 {
    flex: 0 0 83.333333% !important;
    max-width: 83.333333% !important;
  }

  .col-xxxl-11 {
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
  }

  .col-xxxl-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1920px) {
  .col-xxxxl-1 {
    flex: 0 0 8.333333% !important;
    max-width: 8.333333% !important;
  }

  .col-xxxxl-2 {
    flex: 0 0 16.666667% !important;
    max-width: 16.666667% !important;
  }
  .col-xxxxl-2_5 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }

  .col-xxxxl-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-xxxxl-4 {
    flex: 0 0 33.333333% !important;
    max-width: 33.333333% !important;
  }

  .col-xxxxl-5 {
    flex: 0 0 41.666667% !important;
    max-width: 41.666667% !important;
  }

  .col-xxxxl-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .col-xxxxl-7 {
    flex: 0 0 58.333333% !important;
    max-width: 58.333333% !important;
  }

  .col-xxxxl-8 {
    flex: 0 0 66.666667% !important;
    max-width: 66.666667% !important;
  }

  .col-xxxxl-9 {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .col-xxxxl-10 {
    flex: 0 0 83.333333% !important;
    max-width: 83.333333% !important;
  }

  .col-xxxxl-11 {
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
  }

  .col-xxxxl-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 1199px) {
  .cust_pads {
    padding-left: 13px;
    padding-right: 13px;
  }
}

/* end of admin panel */
